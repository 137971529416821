import React, { useContext, useEffect, useState } from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import { GlobalContext } from '../../contexts/GlobalContext';
import { getAllMyLeads, getSiteLeads } from '../../API/leadApi';
import { LeadsParser } from '../../utils/LeadsParser';
import { LEAD_SOURCES } from '../../constants/leadConstants';
import LeadContent from './LeadContent';
import { dateFormatter } from '../../utils/dateFormatter';
import Loader from '../Loader';

const LeadsPage = () => {
  const { currentSite } = useContext(GlobalContext);

  const [leads, setLeads] = useState([]);
  const [count, setCount] = useState();
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [expandedId, setExpandedId] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setPage(0);
    setLimit(20);
  }, [currentSite]);

  useEffect(() => {
    const getAllLeads = async () => {
      if (currentSite) {
        setLoading(true);

        try {
          setLeads([]);

          const response = await getSiteLeads({ page, limit, siteId: currentSite.id });
          setCount(response.count);
          const data = LeadsParser(response.leads);
          setLeads(data);
        } catch (err) {
          console.log(err);
        }
      }
    };

    const getLeads = async () => {
      setLoading(true);

      const res = await getAllMyLeads(page, limit);
      setCount(res.data.count);
      const data = LeadsParser(res.data.leads);
      setLeads(data);
    };
    if (!currentSite || !currentSite.domain) {
      getLeads();
    } else {
      getAllLeads();
    }
    setLoading(false);
  }, [currentSite, page, limit]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  const tableRowClickHandler = (id) => {
    if (expandedId.findIndex((item) => item === id) === -1) {
      setExpandedId((prev) => [...prev, id]);
    } else {
      setExpandedId((prev) => prev.filter((item) => item !== id));
    }
  };

  const getSource = (source) => {
    let text = '';
    if (source === LEAD_SOURCES.CUSTOM) {
      text = 'You have email';
    } else if (source === LEAD_SOURCES.NIMBATA) {
      text = 'You have phone call';
    } else if (source === LEAD_SOURCES.FORM) {
      text = 'You have new from submission';
    }
    return text;
  };
  if (loading) {
    return <Loader />;
  }
  if (!leads.length) {
    return <h2>No leads</h2>;
  }
  return (
    <>
      <h2 style={{ marginTop: 0 }}>My leads</h2>
      <TableContainer padding="none" sx={{ background: 'white' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {!currentSite?.domain && <TableCell align="center">Page</TableCell>}

              <TableCell sx={{ width: '100%' }}>Text</TableCell>
              <TableCell align="center">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leads.map((lead) => (
              <React.Fragment key={lead.id}>
                <TableRow onClick={() => tableRowClickHandler(lead.id)}>
                  {!currentSite?.domain && (
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{lead.domain}</TableCell>
                  )}
                  <TableCell>{getSource(lead.source)}</TableCell>

                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {dateFormatter(lead.createdAt)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{
                      paddingBottom: 0,
                      paddingTop: 0,
                      borderWidth: expandedId.includes(lead.id) ? 0.3 : 0,
                    }}
                    colSpan={5}>
                    <Collapse in={expandedId.includes(lead.id)} timeout="auto" unmountOnExit>
                      <LeadContent lead={lead} />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        {count > limit && (
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
              labelRowsPerPage="Cards per page :"
            />
          </div>
        )}
      </TableContainer>
    </>
  );
};

export default LeadsPage;
