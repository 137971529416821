import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import SidebarNavListItem from './SidebarNavListItem';

const SidebarNavList = ({ pages, depth }) => {
  const router = useLocation();
  const currentRoute = router.pathname;

  const renderSidebarNavItems = (items, depthItems) =>
    items.map((page) => {
      if (page.children) {
        const open = page.href && !!matchPath({ path: page.href, end: false }, currentRoute);

        return (
          <SidebarNavListItem
            depth={depthItems}
            icon={page.icon}
            key={page.title}
            badge={page.badge}
            open={open}
            title={page.title}
            href={page.href}>
            <SidebarNavList pages={page.children} depth={depthItems + 1} />
          </SidebarNavListItem>
        );
      }

      return (
        <SidebarNavListItem
          depth={depthItems}
          href={page.href}
          icon={page.icon}
          key={page.title}
          badge={page.badge}
          title={page.title}
        />
      );
    });

  return <>{renderSidebarNavItems(pages, depth)}</>;
};

export default SidebarNavList;
