import React from 'react';
import { Navigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import styled from '@emotion/styled';

import useAuth from '../../hooks/useAuth';

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100vw;
  height: 100vh;
`;

// For routes that can only be accessed by authenticated users
const AuthGuard = ({ children }) => {
  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized) {
    return (
      <Root>
        <CircularProgress color="secondary" />
      </Root>
    );
  }

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  return children;
};

export default AuthGuard;
