const pagesSection = [
  {
    href: '/user/dashboard',
    title: 'Dashboard',
  },
  {
    href: 'pages',
    title: 'My pages',
  },
  {
    href: 'leads',
    title: 'My leads',
  },
];

const navItems = [
  {
    title: 'Pages',
    pages: pagesSection,
  },
];

export default navItems;
