import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import {
  Card as MuiCard,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  Link,
} from '@mui/material';
import { spacing } from '@mui/system';
import { getSitesList } from '../../API/siteApi';

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const SitesList = () => {
  const [sitesList, setSitesList] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState();
  const [limit, setLimit] = useState(20);

  useEffect(() => {
    const updateSite = async () => {
      const data = await getSitesList(search, page, limit);
      setCount(data.count);
      setSitesList(data.sites);
    };
    updateSite();
  }, [search, page]);

  const searchInputHandler = (e) => {
    setPage(0);
    setSearch(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  return (
    <>
      <h2 style={{ marginLeft: '16px', marginTop: 0 }}>Sites list</h2>
      <Card mb={6}>
        <TextField
          sx={{ margin: '16px', marginBottom: 0 }}
          id="standard-basic"
          label="Page search"
          variant="outlined"
          onChange={searchInputHandler}
          value={search}
          size="small"
        />
        <Paper>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Page</TableCell>
                  <TableCell>SanityId</TableCell>
                  <TableCell>OwnerId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sitesList?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.domain}
                    </TableCell>
                    <TableCell>
                      <Link
                        href={`https://addictive.sanity.studio/desk/all;All;${row.sanityId};${row.sanityId}`}
                        target="_blank"
                        rel="noreferrer">
                        {row.sanityId}
                      </Link>
                    </TableCell>
                    <TableCell>{row.ownerId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
          {count > limit && (
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeLimit}
              />
            </div>
          )}
        </Paper>
      </Card>
    </>
  );
};

export default SitesList;
