import React from 'react';
import { Navigate } from 'react-router-dom';

// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';

// Guards
import AuthGuard from './components/guards/AuthGuard';

// Auth components
import SignIn from './pages/auth/SignIn';
import Page404 from './pages/auth/Page404';

// Protected routes
import AdminGuard from './components/guards/AdminGuard';
import UsersList from './components/AdminPage/UsersList';
import SitesList from './components/AdminPage/SitesList';
import DomainPage from './components/UserPage/DomainPage';
import LeadsPage from './components/UserPage/LeadsPage';
import LeadsList from './components/AdminPage/LeadsList';
import DashboardPage from './components/UserPage/DashboardPage';
import AdminDashboardPage from './components/AdminPage/AdminDashboardPage';

const routes = [
  {
    path: '/',
    element: <Navigate to="/auth/sign-in" />,
  },

  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'sign-in',
        element: <SignIn />,
      },
    ],
  },
  {
    path: 'user',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/user', element: <Navigate to="/user/dashboard" /> },
      { path: 'dashboard', element: <DashboardPage /> },
      {
        path: 'pages',
        element: <DomainPage />,
      },
      {
        path: 'leads',
        element: <LeadsPage />,
      },
    ],
  },
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <AdminGuard>
          <DashboardLayout />
        </AdminGuard>
      </AuthGuard>
    ),
    children: [
      { path: '/admin', element: <Navigate to="/admin/dashboard" /> },
      { path: 'dashboard', element: <AdminDashboardPage /> },
      {
        path: 'sites',
        element: <SitesList />,
      },
      { path: 'users', element: <UsersList /> },
      { path: 'leads', element: <LeadsList /> },
    ],
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
