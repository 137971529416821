import React from 'react';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';

import { Grid, AppBar as MuiAppBar, IconButton as MuiIconButton, Toolbar } from '@mui/material';

import { Menu as MenuIcon } from '@mui/icons-material';

// import NavbarLanguagesDropdown from './NavbarLanguagesDropdown';
import NavbarUserDropdown from './NavbarUserDropdown';
import ChooseSiteDropdown from '../ChooseSiteDropdown';
import useAuth from '../../hooks/useAuth';

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
  //
`;

const Navbar = ({ onDrawerToggle }) => {
  const { userMe } = useAuth();
  // const { t } = useTranslation();
  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              size="large">
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item xs />
          {userMe === 'user' && <ChooseSiteDropdown />}
          <Grid item>
            {/* <NavbarLanguagesDropdown /> */}
            <NavbarUserDropdown />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
export default withTheme(Navbar);
