import React, { useEffect, useState } from 'react';
import {
  Card,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import { getSiteLeads } from '../../API/leadApi';
import { LeadsParser } from '../../utils/LeadsParser';
import { LEAD_SOURCES } from '../../constants/leadConstants';
import { dateFormatter } from '../../utils/dateFormatter';
import LeadContent from '../UserPage/LeadContent';
import ChooseLeadsSite from './ChooseLeadsSite';

const LeadsList = () => {
  const [leads, setLeads] = useState([]);
  const [count, setCount] = useState();
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [expandedId, setExpandedId] = useState([]);
  const [currentSite, setCurrentSite] = useState();

  useEffect(() => {
    const getAllLeads = async () => {
      try {
        setLeads([]);

        let query = {
          page,
          limit,
        };
        if (currentSite) {
          query = {
            ...query,
            siteId: currentSite.id,
          };
        }
        const response = await getSiteLeads({ ...query });
        setCount(response.count);
        const data = LeadsParser(response.leads);
        setLeads(data);
      } catch (err) {
        console.log(err);
      }
    };

    getAllLeads();
  }, [currentSite, page, limit]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  const tableRowClickHandler = (id) => {
    if (expandedId.findIndex((item) => item === id) === -1) {
      setExpandedId((prev) => [...prev, id]);
    } else {
      setExpandedId((prev) => prev.filter((item) => item !== id));
    }
  };

  const getSource = (source) => {
    let text = '';
    if (source === LEAD_SOURCES.CUSTOM) {
      text = 'You have email';
    } else if (source === LEAD_SOURCES.NIMBATA) {
      text = 'You have phone call';
    } else if (source === LEAD_SOURCES.FORM) {
      text = 'You have new from submission';
    }
    return text;
  };

  const handleChange = (site) => {
    setPage(0);
    setCurrentSite(site);
  };

  return (
    <>
      <h2 style={{ marginLeft: '16px', marginTop: 0 }}>Leads</h2>
      <Card>
        <ChooseLeadsSite currentSite={currentSite} changeCurrentSite={(e) => handleChange(e)} />
        {!leads.length ? (
          <h2 style={{ paddingLeft: '20px' }}>No leads</h2>
        ) : (
          <TableContainer padding="none">
            <Table sx={{ background: 'white' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {!currentSite?.domain && <TableCell>Page</TableCell>}
                  <TableCell sx={{ width: '100%' }}>Description</TableCell>

                  <TableCell align="center">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leads.map((lead) => (
                  <React.Fragment key={lead.id}>
                    <TableRow
                      sx={{ cursor: 'pointer' }}
                      onClick={() => tableRowClickHandler(lead.id)}>
                      {!currentSite?.domain && (
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>{lead.domain}</TableCell>
                      )}
                      <TableCell>{getSource(lead.source)}</TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {dateFormatter(lead.createdAt)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          borderWidth: expandedId.includes(lead.id) ? 0.3 : 0,
                        }}
                        colSpan={5}>
                        <Collapse in={expandedId.includes(lead.id)} timeout="auto" unmountOnExit>
                          <LeadContent lead={lead} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
            {count > limit && (
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeLimit}
                  labelRowsPerPage="Cards per page :"
                />
              </div>
            )}
          </TableContainer>
        )}
      </Card>
    </>
  );
};

export default LeadsList;
