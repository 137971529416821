import axios from '../utils/axios';

export const getSiteLeads = async ({ page, limit, siteId }) => {
  let query = '';
  try {
    if (page !== 0) {
      query += `page=${page}`;
    }
    if (limit !== 20) {
      query += `&limit=${limit}`;
    }
    if (siteId) {
      query += `&siteId=${siteId}`;
    }
    const response = await axios.get(`/api/my-pages/emails?${query}`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getAllMyLeads = async (page = 0, limit = 20) => {
  let query = '';
  try {
    if (page !== 0) {
      query += `page=${page}`;
    }
    if (limit !== 20) {
      query += `&limit=${limit}`;
    }
    const response = await axios.get(`/api/my-pages/emails/my?${query}`);
    return response;
  } catch (error) {
    return console.error(error);
  }
};
