import { createContext, useMemo, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const [currentSite, setCurrentSite] = useState();

  const contextValue = useMemo(() => ({ currentSite, setCurrentSite }), [currentSite]);

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
};
