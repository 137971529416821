import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
// import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
} from '@mui/material';
import { spacing } from '@mui/system';

import useAuth from '../../hooks/useAuth';

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const SignIn = () => {
  const navigate = useNavigate();
  const { signIn, setUserMe } = useAuth();

  return (
    <Formik
      initialValues={{
        email: 'admin@email.com',
        password: 'admin',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const roleCode = await signIn(values.email, values.password);
          const role = roleCode === 1 ? 'admin' : 'user';
          setUserMe(role);
          navigate(`/${role}`);
        } catch (error) {
          const message = error.message || 'Something went wrong';

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}>
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button type="submit" fullWidth variant="contained" color="primary">
            Sign in
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default SignIn;
