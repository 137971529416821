import { Card, CardContent, Typography } from '@mui/material';
import { LEAD_SOURCES } from '../../constants/leadConstants';
import { dateFormatter } from '../../utils/dateFormatter';
import { decodeSpecialCharacters } from '../../utils/decodeSpecialCharacters';

const LeadContent = ({ lead }) => {
  if (lead.source === LEAD_SOURCES.CUSTOM) {
    return (
      <>
        {lead.from && (
          <Typography sx={{ marginTop: '20px', marginBottom: '20px' }} variant="h4">
            <b>From :</b> {lead.from}
          </Typography>
        )}
        {lead.content.length ? (
          <Typography>
            <b>Content :</b> {decodeSpecialCharacters(lead.content)}
          </Typography>
        ) : (
          ''
        )}
      </>
    );
  }
  if (lead.source === LEAD_SOURCES.NIMBATA) {
    if (typeof lead.data === 'object') {
      return (
        <>
          <Typography sx={{ marginTop: '20px', marginBottom: '20px' }} variant="h4">
            <b>Tracking Number: </b> {lead.data['Tracking Number']}
          </Typography>

          <Typography>
            <b>Start Time (local timezone): </b>
            {dateFormatter(lead.data['Start Time (local timezone)'])}
          </Typography>

          <Typography>
            <b>Call Outcome: </b>
            {lead.data['Call Outcome']}
          </Typography>

          <Typography sx={{ marginBottom: '20px' }}>
            <b>Caller ID: </b>
            {lead.data['Caller ID']}
          </Typography>
        </>
      );
    }
    if (typeof lead.data === 'string') {
      let content = lead.data;
      const bodyStart = lead.data.indexOf('<body>');
      const bodyEnd = lead.data.indexOf('</body>');
      if (bodyStart !== -1 && bodyEnd !== -1) {
        content = lead.data.substring(bodyStart + 6, bodyEnd);
      }
      const decodedContent = decodeSpecialCharacters(content);
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: decodedContent,
          }}
        />
      );
    }
  }
  if (lead.source === LEAD_SOURCES.FORM) {
    if (typeof lead.data === 'object') {
      return (
        <Card>
          <CardContent>
            {lead.data.from && (
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                From : {lead.data.from}
              </Typography>
            )}
            <Typography sx={{ marginTop: '20px', marginBottom: '20px' }} variant="h4">
              {lead.data.Namn}
            </Typography>
            {Object.keys(lead.data).map((key) => {
              if (key !== 'from' && key !== 'Namn' && lead.data[key].length) {
                if (key === 'content') {
                  return (
                    <Typography key={key}>
                      <b style={{ 'text-transform': 'capitalize' }}>{key} :</b>{' '}
                      {decodeSpecialCharacters(lead.data[key])}
                    </Typography>
                  );
                }
                return (
                  <Typography key={key}>
                    <b>{key} :</b> {lead.data[key]}
                  </Typography>
                );
              }
              return null;
            })}
          </CardContent>
        </Card>
      );
    }
    if (typeof lead.data === 'string') {
      let content = lead.data;
      const bodyStart = lead.data.indexOf('<body>');
      const bodyEnd = lead.data.indexOf('</body>');
      if (bodyStart !== -1 && bodyEnd !== -1) {
        content = lead.data.substring(bodyStart + 6, bodyEnd);
      }
      const decodedContent = decodeSpecialCharacters(content);
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: decodedContent,
          }}
        />
      );
    }
  }

  return null;
};

export default LeadContent;
