import axios from '../utils/axios';

export const getSitesList = async (search, page = 0, limit = 20) => {
  let query = '';
  if (search?.length > 0) {
    query += `search=${search}`;
  }
  if (page !== 0) {
    query += `&page=${page}`;
  }
  if (limit !== 20) {
    query += `&limit=${limit}`;
  }
  const { data } = await axios.get(`/api/my-pages/site?${query}`);

  return data;
};

export const changeSiteOwner = async (body) => {
  try {
    const response = await axios.put('/api/my-pages/site', body);
    return response;
  } catch (err) {
    return err;
  }
};

export const getUserSites = (userId) => {
  const response = axios.get(`/api/my-pages/site/site?userId=${userId}`);
  return response;
};

export const getMySites = async (search, page = 0, limit = 20) => {
  let query = '';
  try {
    if (search?.length > 0) {
      query += `search=${search}`;
    }
    if (page !== 0) {
      query += `&page=${page}`;
    }
    if (limit !== 20) {
      query += `&limit=${limit}`;
    }
    const response = await axios.get(`/api/my-pages/site/my?${query}`);
    return response.data;
  } catch (err) {
    return err;
  }
};
