import { useContext, useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { getMySites } from '../API/siteApi';
import { GlobalContext } from '../contexts/GlobalContext';

const ChooseSiteDropdown = () => {
  const context = useContext(GlobalContext);
  const { currentSite, setCurrentSite } = context;
  const [search, setSearch] = useState(currentSite?.domain || '');
  const [searchSiteList, setSearchSiteList] = useState([]);
  useEffect(() => {
    const getSearchSite = async () => {
      if (search?.length >= 3) {
        const response = await getMySites();
        setSearchSiteList(response.sites.rows);
      } else {
        setSearchSiteList([]);
      }
    };
    getSearchSite();
  }, [search]);

  const defaultProps = {
    options: searchSiteList,
    getOptionLabel: (option) => option.domain,
  };
  return (
    <Autocomplete
      {...defaultProps}
      options={searchSiteList}
      sx={{ width: 300, margin: '12px' }}
      size="small"
      onChange={(event, newValue) => {
        setCurrentSite({ domain: newValue?.domain, id: newValue?.id });
        setSearch(newValue?.domain);
      }}
      renderInput={(params) => (
        <TextField
          value={search}
          {...params}
          onChange={(e) => setSearch(e.target.value)}
          label="Current page"
        />
      )}
    />
  );
};

export default ChooseSiteDropdown;
