const pagesSection = [
  {
    href: '/admin/dashboard',
    title: 'Dashboard',
  },
  {
    href: 'sites',
    title: 'Sites',
  },
  {
    href: 'users',
    title: 'Users',
  },
  {
    href: 'leads',
    title: 'Leads',
  },
];

const navItems = [
  {
    title: 'Pages',
    pages: pagesSection,
  },
];

export default navItems;
