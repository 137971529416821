import axios from '../utils/axios';

export const getUsersList = async ({ search, page, limit }) => {
  let query = '';
  if (search?.length > 0) {
    query += `search=${search}`;
  }
  if (page !== 0) {
    query += `&page=${page}`;
  }
  if (limit !== 20) {
    query += `&limit=${limit}`;
  }
  const { data } = await axios.get(`/api/my-pages/user?${query}`);
  return data;
};

export const createUser = async (data) => {
  const response = await axios.post('/api/my-pages/user', { ...data });
  return response;
};

export const getDashboard = async () => {
  const { data } = await axios.get('/api/my-pages/dashboard/user');
  return data;
};

export const getAdminDashboard = async () => {
  const { data } = await axios.get('/api/my-pages/dashboard/admin');
  return data;
};
