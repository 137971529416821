import { useEffect, useState } from 'react';

import { Divider as MuiDivider, Grid, Typography as MuiTypography } from '@mui/material';

import styled from '@emotion/styled';
import { spacing } from '@mui/system';

import Stats from '../../pages/dashboards/Default/Stats';
import { getAdminDashboard } from '../../API/usersApi';
import Loader from '../Loader';

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const AdminDashboardPage = () => {
  const [stats, setStats] = useState();

  useEffect(() => {
    const getDashboard = async () => {
      const response = await getAdminDashboard();
      setStats(response);
    };
    getDashboard();
  }, []);

  if (!stats) {
    return <Loader />;
  }

  return (
    <>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard {stats.firstName} {stats.lastName}
          </Typography>
          <Typography variant="subtitle1">
            Welcome back!
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Stats title="Total Leads" amount={stats.leadsCount} chip="Annual" />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Stats title="Total Sites" amount={stats.siteCount} chip="Annual" />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Stats title="Total Users" amount={stats.usersCount} chip="Annual" />
        </Grid>
      </Grid>
    </>
  );
};
export default AdminDashboardPage;
