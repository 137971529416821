import { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getSitesList } from '../../API/siteApi';

const DropdownInput = ({ onChange }) => {
  const [search, setSearch] = useState('');
  const [searchSiteList, setSearchSiteList] = useState([]);

  useEffect(() => {
    const getSearchSite = async () => {
      if (search?.length >= 3) {
        const response = await getSitesList(search, 0, 1000);
        setSearchSiteList(response.sites);
      } else {
        setSearchSiteList([]);
      }
      onChange(search);
    };
    getSearchSite();
  }, [search]);

  const defaultProps = {
    options: searchSiteList,
    getOptionLabel: (option) => option.domain,
  };

  return (
    <Autocomplete
      {...defaultProps}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      sx={{ width: 300, marginTop: '12px' }}
      size="small"
      onChange={(event, newValue) => {
        setSearch(newValue?.domain);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label="Enter page"
        />
      )}
    />
  );
};

export default DropdownInput;
