import {
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { changeSiteOwner, getUserSites } from '../../API/siteApi';
import DropdownInput from './DropdownInput';

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '15px',
  p: 4,
};

const AssignSiteOwner = ({ onReturn, userId }) => {
  const [dataUserSites, setDataUserSites] = useState();
  const [open, setOpen] = useState();
  const [newDomain, setNewDomain] = useState();

  const getSites = async () => {
    try {
      const response = await getUserSites(userId);
      return setDataUserSites(response.data.sites);
    } catch (err) {
      return console.error(err);
    }
  };

  useEffect(() => {
    getSites();
  }, []);

  const addOwnerSite = async () => {
    try {
      const body = { userId, domain: newDomain };
      await changeSiteOwner(body);
      await getSites();
      setOpen(false);
      setNewDomain('');
    } catch (err) {
      console.error(err);
    }
  };

  const isDomainHandler = (value) => {
    setNewDomain(value);
  };

  return (
    <>
      <Button onClick={() => onReturn(false)} sx={{ marginTop: '12px' }}>
        Return
      </Button>
      <Card mb={6}>
        <CardHeader
          action={
            <Button
              variant="contained"
              sx={{ marginLeft: '20px', marginTop: '12px' }}
              onClick={() => setOpen(true)}>
              +Add Site
            </Button>
          }
          title="User Sites List"
        />

        <Paper>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Page</TableCell>

                  <TableCell>Site Id</TableCell>
                  <TableCell>User Id</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataUserSites?.map((row) => (
                  <TableRow key={row.sanityId}>
                    <TableCell component="th" scope="row">
                      {row.domain}
                    </TableCell>
                    <TableCell>{row.sanityId}</TableCell>
                    <TableCell>{row.ownerId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        </Paper>
      </Card>

      {open && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Site
              <CloseIcon onClick={() => setOpen(false)} sx={{ float: 'right' }} />
            </Typography>
            <DropdownInput onChange={isDomainHandler} />

            <Button
              sx={{ marginTop: '10px', marginLeft: '10px' }}
              variant="outlined"
              onClick={addOwnerSite}>
              Add
            </Button>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default AssignSiteOwner;
