import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import styled from '@emotion/styled';

import { createUser } from '../../API/usersApi';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '15px',
  p: 4,
};

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`;

const ModalCreateNewUser = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: 2,
    password: '',
  });

  const validateForm = () => {
    const errors = {};

    if (!formData.email) {
      errors.email = 'Please enter an email';
    }
    if (!formData.password) {
      errors.password = 'Please enter a password';
    }
    if (formData.password !== repeatPassword) {
      errors.repeatPassword = 'Password mismatch';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmitNewUserFormData = async () => {
    setLoading(true);
    const response = await createUser(formData);
    if (response.status === 201) {
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        role: 2,
        password: '',
      });
      setRepeatPassword('');
      onClose();
    } else {
      console.error(response.status, response.statusText);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create user
          <CloseIcon onClick={() => onClose()} sx={{ float: 'right' }} />
        </Typography>
        <Box component="form" autoComplete="off">
          <div
            style={{
              marginTop: '15px',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              width: '100%',
            }}>
            <TextField
              label="First Name"
              size="small"
              value={formData.firstName}
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
            />
            <TextField
              label="Last Name"
              size="small"
              value={formData.lastName}
              onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
            />
            <TextField
              required
              label="Email"
              size="small"
              value={formData.email}
              onChange={(e) => {
                setFormErrors((prevErrors) => ({ ...prevErrors, email: undefined }));
                setFormData({ ...formData, email: e.target.value });
              }}
              error={formErrors.email !== undefined}
              helperText={formErrors.email}
            />

            <TextField
              required
              label="Password"
              type="password"
              size="small"
              value={formData.password}
              onChange={(e) => {
                setFormErrors((prevErrors) => ({ ...prevErrors, password: undefined }));
                setFormData({ ...formData, password: e.target.value });
              }}
              error={formErrors.password !== undefined}
              helperText={formErrors.password}
            />
            <TextField
              required
              label="Repeat password"
              type="password"
              size="small"
              value={repeatPassword}
              onChange={(e) => {
                setFormErrors((prevErrors) => ({ ...prevErrors, repeatPassword: undefined }));
                setRepeatPassword(e.target.value);
              }}
              error={formErrors.repeatPassword !== undefined}
              helperText={formErrors.repeatPassword}
            />

            <RadioGroup
              sx={{ display: 'flex', justifyContent: 'space-around' }}
              required
              row
              aria-labelledby="role-radio-group"
              defaultValue={2}
              onChange={(e) => setFormData({ ...formData, role: +e.target.value })}>
              <FormControlLabel value={1} control={<Radio />} label="Admin" />
              <FormControlLabel value={2} control={<Radio />} label="User" />
            </RadioGroup>
          </div>
          {loading ? (
            <Root>
              <CircularProgress color="secondary" size="30px" />
            </Root>
          ) : (
            <div>
              <Button
                style={{ width: '100%' }}
                variant="contained"
                onClick={() => {
                  if (validateForm()) {
                    handleSubmitNewUserFormData();
                  }
                }}>
                Create user
              </Button>
            </div>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalCreateNewUser;
