import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';

import {
  Card as MuiCard,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  Button,
} from '@mui/material';
import { spacing } from '@mui/system';
import { getUsersList } from '../../API/usersApi';
import AssignSiteOwner from './AssignSiteOwner';
import ModalCreateNewUser from './ModalCreateUser';

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const UsersList = () => {
  const [usersList, setUsersList] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState();
  const [limit, setLimit] = useState(20);
  const [openUserSiteList, setOpenUserSiteList] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);

  const updateUser = async () => {
    const data = await getUsersList({ search, page, limit });
    setCount(data.count);
    setUsersList(data.users);
  };

  useEffect(() => {
    updateUser();
  }, [search, page, limit]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCloseUserSiteList = () => {
    setCurrentUser(null);
    setOpenUserSiteList(false);
  };

  const handleOpenUserSiteList = (userId) => {
    setCurrentUser(userId);
    setOpenUserSiteList(true);
  };

  const onCloseModal = async () => {
    setOpenCreateUserModal(false);
    await updateUser();
  };

  const searchInputHandler = (e) => {
    setPage(0);
    setSearch(e.target.value);
  };

  if (openUserSiteList) {
    return <AssignSiteOwner onReturn={handleCloseUserSiteList} userId={currentUser} />;
  }
  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px',
          paddingTop: 0,
        }}>
        <h2 style={{ margin: 0 }}>Users list</h2>
        <Button variant="contained" onClick={() => setOpenCreateUserModal(true)}>
          Create user
        </Button>
      </div>

      <Card mb={6}>
        <TextField
          sx={{ margin: '16px', marginBottom: 0 }}
          id="standard-basic"
          label="Email search"
          variant="outlined"
          onChange={searchInputHandler}
          value={search}
          size="small"
        />

        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>First name</TableCell>
                <TableCell>Second name</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>User sites</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersList?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell>{row.lastName}</TableCell>
                  <TableCell>{row.role === 1 ? 'Admin' : 'User'}</TableCell>
                  <TableCell>
                    <EditIcon onClick={() => handleOpenUserSiteList(row.id)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {count > limit && (
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeLimit}
              />
            </div>
          )}
        </TableWrapper>
      </Card>

      {openCreateUserModal && (
        <ModalCreateNewUser isOpen={openCreateUserModal} onClose={() => onCloseModal()} />
      )}
    </>
  );
};

export default UsersList;
