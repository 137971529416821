import { LEAD_SOURCES } from '../constants/leadConstants';

export const LeadsParser = (leads) => {
  const allLeads = [];
  leads.map((leadData) => {
    const lead = leadData.dataValues || leadData;
    let data;
    try {
      data = JSON.parse(lead.data);
    } catch (error) {
      data = lead.data;
    }
    let leadObj;
    if (lead.source === LEAD_SOURCES.CUSTOM) {
      const content = data.content.replace(/<\/?[^>]+(>|$)/g, '');
      leadObj = {
        id: lead.id,
        createdAt: lead.createdAt,
        source: lead.source,
        from: data.from,
        content,
        domain: lead.site?.domain || leadData.domain,
      };
    }
    if (lead.source === LEAD_SOURCES.NIMBATA) {
      leadObj = {
        id: lead.id,
        createdAt: lead.createdAt,
        source: lead.source,
        domain: lead.site?.domain || leadData.domain,
        data,
      };
    }
    if (lead.source === LEAD_SOURCES.FORM) {
      leadObj = {
        id: lead.id,
        createdAt: lead.createdAt,
        source: lead.source,
        domain: lead.site?.domain || leadData.domain,
        data,
      };
    }
    return allLeads.push({ ...leadObj });
  });

  return allLeads;
};
