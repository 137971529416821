import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';

import { Paper } from '@mui/material';

import SignInComponent from '../../components/auth/SignIn';

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};token, option
  }
`;

const SignIn = () => (
  <Wrapper>
    <Helmet title="Sign In" />

    <SignInComponent />
  </Wrapper>
);

export default SignIn;
