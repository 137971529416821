import { useEffect, useState } from 'react';

import {
  Card as MuiCard,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import styled from '@emotion/styled';
import { spacing } from '@mui/system';

import { getMySites } from '../../API/siteApi';

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const DomainPage = () => {
  const [mySitesList, setMySitesList] = useState([]);
  const [count, setCount] = useState();
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const getMyDomainsList = async () => {
      const response = await getMySites(search, page, limit);
      setCount(response.sites.count);
      setMySitesList(response.sites.rows);
    };
    getMyDomainsList();
  }, [search, page]);

  useEffect(() => setPage(0), [search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  const searchInputHandler = (e) => {
    setPage(0);
    setSearch(e.target.value);
  };

  if (!mySitesList.length) {
    return <h2>You don&apos;t have pages</h2>;
  }

  return (
    <>
      <h2 style={{ marginTop: 0 }}>My pages</h2>

      <Card mb={6}>
        <TableWrapper sx={{ background: 'white' }}>
          <TextField
            sx={{ margin: '16px', marginBottom: 0 }}
            id="standard-basic"
            label="Page search"
            variant="outlined"
            onChange={searchInputHandler}
            value={search}
            size="small"
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Page</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mySitesList?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.domain}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {count > limit && (
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeLimit}
                labelRowsPerPage="Cards per page :"
              />
            </div>
          )}
        </TableWrapper>
      </Card>
    </>
  );
};

export default DomainPage;
